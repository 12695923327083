import React from 'react';

import tw, {styled, css} from 'twin.macro';

const Wrapper = styled.div(() => [
	tw``
]);


interface iStepProps {
	isLast: boolean
}

const Step = styled.li<iStepProps>(({isLast}) => [
	tw`relative pb-10`,
	isLast && tw`pb-0`
]);

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {

}

interface iStep {
	title: string;
	description: string;
}

const steps: iStep[] = [{
	title      : "Select VPN type",
	description: "Start by selecting the desired VPN type you want to use"
}, {
	title      : "Adjust the configuration",
	description: "Play with our create VPN form and adjust every detail for your instance"
}, {
	title      : "Submit payment",
	description: "Submit your payment so we can begin spawning your server and installing the selected service"
}, {
	title      : "Receive connection config",
	description: "Wait for the email containing your configuration file. It usually arrives immediately after the server is installed"
}];

const StepProcess: React.FunctionComponent<Props> = (props: Props) => {
	return (
		<Wrapper>
			<div className="grid gap-10 sm:mt-16 sm:space-y-0 sm:gap-4 lg:gap-16 grid-cols-1 lg:grid-cols-2 gap-10 items-center">
				<div className={`relative text-center`}>
					<div className="z-10">
						<b className="block text-3xl mb-4 font-extrabold text-bg-text sm:text-4xl sm:leading-10 px-12">
							The shortest path is always a straight line
						</b>

						<p className={"px-8 text-2xl text-gray-500"}>
							Setting your own VPN server is hard and takes a lot of time. Let
							us build you a safe and secure instance in minutes.
						</p>
					</div>

					<svg className="absolute z-0 left transform md:hidden lg:block -translate-y-3/4 -translate-x-1/4 lg:-translate-x-4/5"
						width="260" height="360" fill="none" viewBox="0 0 260 360">
						<defs>
							<pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20"
								    patternUnits="userSpaceOnUse">
								<rect x="0" y="0" width="4" height="4" className="text-gray-200"
									 fill="currentColor"/>
							</pattern>
						</defs>
						<rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"/>
					</svg>
				</div>
				<nav className={`bg-bg-background rounded-lg py-6 px-6`}>
					<ul className="overflow-hidden">
						{steps.map((s: iStep, i: number) => (
							<Step isLast={i == steps.length - 1} key={`step-${i}`}>
								{i != steps.length - 1 && (
									<div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"/>
								)}
								<div className="relative flex items-start space-x-4 group focus:outline-none">
									<div className="h-9 flex items-center">
										<span
											className="relative z-10 w-8 h-8 flex items-center justify-center bg-primary-text border-2 border-gray-300 rounded-full group-hover:border-gray-400 group-focus:border-gray-400 transition ease-in-out duration-150">
											<span
												className="h-2.5 w-2.5 bg-transparent rounded-full bg-gray-200 group-hover:bg-gray-300 group-focus:bg-gray-300 transition ease-in-out duration-150"/>
										</span>
									</div>
									<div className="min-w-0">
										<b className="text-xs leading-4 font-semibold uppercase tracking-wide text-gray-500">
											{s.title}
										</b>
										<p className="text-sm leading-5 text-gray-500">
											{s.description}
										</p>
									</div>
								</div>
							</Step>
						))}
					</ul>
				</nav>
			</div>
		</Wrapper>
	);
};

export default StepProcess;
